import { createApp, provide, h } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import SetupInterceptors from "@/core/services/SetupInterceptors";
import eventBus from "vue3-eventbus";
import "@/core/plugins/prismjs";

import { DefaultApolloClient } from "@vue/apollo-composable";

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
});

// const app = createApp(App);
import tinymce from "tinymce/tinymce";
import { apolloClient } from "./store/ApolloClient";
import { clickOutside } from "./core/helpers/clickOutside";
app.use(tinymce);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(eventBus);
app.directive("click-outside", clickOutside);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
SetupInterceptors();

app.mount("#app");
