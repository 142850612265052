import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/user/list",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/user/list",
        name: "user.list",
        component: () => import("@/views/manager/pages/user/list/index.vue"),
        meta: {
          pageTitle: "ユーザ一覧",
          breadcrumbs: [],
        },
      },
      {
        path: "/user/detail/:id",
        name: "user.detail",
        component: () => import("@/views/manager/pages/user/detail/index.vue"),
        meta: {
          pageTitle: "ユーザ詳細",
          breadcrumbs: [
            {
              title: "ユーザ管理",
              routeName: "user.list",
            },
            {
              title: "ユーザ詳細",
              routeName: "user.detail",
            },
          ],
        },
      },
      {
        path: "/product/list",
        name: "product.list",
        component: () => import("@/views/manager/pages/product/list/index.vue"),
        meta: {
          pageTitle: "出品一覧",
          breadcrumbs: [],
        },
      },
      {
        path: "/product/detail/:id",
        name: "product.detail",
        component: () =>
          import("@/views/manager/pages/product/detail/index.vue"),
        meta: {
          pageTitle: "出品詳細",
          breadcrumbs: [
            {
              title: "出品管理",
              routeName: "product.list",
            },
            {
              title: "出品詳細",
              routeName: "product.detail",
            },
          ],
        },
      },
      {
        path: "/notification/list",
        name: "notification.list",
        component: () =>
          import("@/views/manager/pages/notification/list/index.vue"),
        meta: {
          pageTitle: "お知らせ一覧",
          btnNew: {
            text: "新規登録",
            routeName: "notification.add",
          },
          breadcrumbs: [],
        },
      },

      {
        path: "/notification/add",
        name: "notification.add",
        component: () =>
          import("@/views/manager/pages/notification/add_edit/index.vue"),
        meta: {
          pageTitle: "お知らせ作成",
          breadcrumbs: [
            {
              title: "お知らせ管理",
              routeName: "notification.list",
            },
            {
              title: "お知らせ作成",
              routeName: "notification.add",
            },
          ],
        },
      },

      {
        path: "/notification/edit/:id",
        name: "notification.edit",
        component: () =>
          import("@/views/manager/pages/notification/add_edit/index.vue"),
        meta: {
          pageTitle: "お知らせ編集",
          breadcrumbs: [
            {
              title: "お知らせ管理",
              routeName: "notification.list",
            },
            {
              title: "お知らせ編集",
              routeName: "notification.edit",
            },
          ],
        },
      },
      {
        path: "/notification/detail/:id",
        name: "notification.detail",
        component: () =>
          import("@/views/manager/pages/notification/detail/index.vue"),
        meta: {
          pageTitle: "お知らせ詳細",
          breadcrumbs: [
            {
              title: "お知らせ管理",
              routeName: "notification.list",
            },
            {
              title: "お知らせ詳細",
              routeName: "notification.detail",
            },
          ],
        },
      },
      {
        path: "/news/list",
        name: "news.list",
        component: () => import("@/views/manager/pages/news/list/index.vue"),
        meta: {
          pageTitle: "ニュース一覧",
          btnNew: {
            text: "新規登録",
            routeName: "news.add",
          },
          breadcrumbs: [],
        },
      },
      {
        path: "/news/add",
        name: "news.add",
        component: () =>
          import("@/views/manager/pages/news/add_edit/index.vue"),
        meta: {
          pageTitle: "ニュース作成",
          breadcrumbs: [
            {
              title: "ニュース一覧",
              routeName: "news.list",
            },
            {
              title: "ニュース作成",
              routeName: "news.add",
            },
          ],
        },
      },
      {
        path: "/news/edit/:id",
        name: "news.edit",
        component: () =>
          import("@/views/manager/pages/news/add_edit/index.vue"),
        meta: {
          pageTitle: "ニュース編集",
          breadcrumbs: [
            {
              title: "ニュース管理",
              routeName: "news.list",
            },
            {
              title: "ニュース編集",
              routeName: "news.edit",
            },
          ],
        },
      },
      {
        path: "/news/detail/:id",
        name: "news.detail",
        component: () => import("@/views/manager/pages/news/detail/index.vue"),
        meta: {
          pageTitle: "ニュース詳細",
          breadcrumbs: [
            {
              title: "ニュース一覧",
              routeName: "news.list",
            },
            {
              title: "ニュース詳細",
              routeName: "news.detail",
            },
          ],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/manager/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "ログイン",
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
          import(
            "@/views/manager/authentication/basic-flow/ForgotPassword.vue"
          ),
        meta: {
          pageTitle: "パスワード再設定",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/manager/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/manager/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/manager/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/manager/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.middleware == "auth") {
    if (!JwtService.getToken()) {
      next({ name: "sign-in" });
    }
  }

  // current page view title
  document.title = `${to.meta.pageTitle}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  next();
});

export default router;
