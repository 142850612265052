import Swal from "sweetalert2/dist/sweetalert2.min.js";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 5000,
});

const ToastErr = Toast;
ToastErr.timer = 10000;

const toastMsg = (msg: string, type = "success"): void => {
  Toast.fire({
    icon: type,
    title: msg,
  });
};
const toastMsgSuccess = (msg: string, timer = 5000): void => {
  Toast.fire({
    icon: "success",
    background: "#CBE9BF",
    title: msg,
    timer: timer,
  });
};
const toastMsgError = (msg: string): void => {
  ToastErr.fire({
    icon: "error",
    background: "#ED8FA3",
    title: msg,
  });
};

const alertMsg = (msg: string, icon = "success", callBack?) => {
  return Swal.fire({
    title: msg,
    icon: icon,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: "OK",
    cancelButtonText: "CLOSE",
    preConfirm: callBack && callBack,
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: "btn fw-semobold btn-primary",
      cancelButton: "btn fw-semobold btn-light-danger",
    },
  });
};

const alertMsgError = (msg: string, icon = "error") => {
  return Swal.fire({
    title: msg,
    icon: icon,
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "btn fw-semobold btn-danger",
    },
  });
};

const confirmMsg = (msg: string) => {
  return Swal.fire({
    title: msg,
    icon: "warning",
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: "Có",
    cancelButtonText: "Không",
    customClass: {
      confirmButton: "btn fw-semobold btn-primary",
      cancelButton: "btn fw-semobold btn-light-danger",
    },
  });
};

const confirmLoader = (
  msg: string,
  preconfirmFunction,
  confirmButtonText: string,
  cancelButtonText: string
) => {
  return Swal.fire({
    title: msg,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: confirmButtonText || "OK",
    cancelButtonText: cancelButtonText || "キャンセル",
    preConfirm: preconfirmFunction,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  });
};

export {
  toastMsg,
  toastMsgSuccess,
  toastMsgError,
  alertMsg,
  confirmMsg,
  confirmLoader,
  alertMsgError,
};
