import { hideModal } from "@/core/helpers/dom";
import { toastMsgError, toastMsgSuccess } from "@/core/helpers/toastMessage";
import { Store } from "vuex";
import {
  FILE_SIZE,
  FILE_TYPE_VALIDATE,
  FILE_TYPE_ACCEPTED,
} from "../constant/const";
import { EFileType } from "../enums/enums";
import axios from "axios";
import { getToken } from "@/core/services/JwtService";

export const isNil = (v) => v === null || v === undefined;

export const createFormData = (dataObject, excludeFields: string[] = []) => {
  const formData = new FormData();
  const keys = Object.keys(dataObject);
  if (keys.length === 0) return formData;
  keys.forEach((key) => {
    if (isNil(dataObject[key]) && excludeFields.includes(key)) return;
    if (Array.isArray(dataObject[key])) {
      dataObject[key].forEach((e) => {
        formData.append(`${key}[]`, e);
      });
      return;
    }
    formData.append(key, isNil(dataObject[key]) ? "" : dataObject[key]);
  });
  return formData;
};

export const deepClone = (obj: object) => {
  return JSON.parse(JSON.stringify(obj));
};

export const handleShowMessage = (
  store: Store<any>,
  msgSuccess = "",
  refModal?: HTMLElement | null,
  callBack?: any,
  timer = 5000
) => {
  const [errorName] = Object.keys(store.getters.getErrors?.errors || {});
  const message_error = store.getters.getErrors?.errors?.[errorName]?.[0];
  if (!message_error) {
    refModal && hideModal(refModal);
    msgSuccess && toastMsgSuccess(msgSuccess, timer);
    callBack && callBack();
  } else {
    toastMsgError(message_error);
  }
};

export const handleValidateFile = (
  file: File,
  fileType: EFileType = EFileType.Image
) => {
  switch (fileType) {
    case EFileType.Image:
      if (!FILE_TYPE_VALIDATE.IMAGE.includes(file.type)) {
        return `Vui lòng tải lên tệp ở định dạng ${FILE_TYPE_ACCEPTED.IMAGE}.`;
      }
      if (file.size > FILE_SIZE["2MB"]) {
        return "Vui lòng tải lên tệp không lớn hơn 2MB";
      }
      return "";

    default:
      return "";
  }
};

export const mappingList = (options, key = "id") => {
  return (options || []).map((e) => ({
    value: `${e[key]}`,
    label: e.name,
  }));
};

export const sleep = (ms = 500) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const saveFileToServer = async (
  file,
  type
): Promise<{ file_name: string; file_url: string; file_size: string }> => {
  try {
    const blobValue = new Blob([file], { type: file.type });

    const formdata = new FormData();
    formdata.append(
      "operations",
      `{\n  "query": "mutation ($fileUploads: Upload) { uploadFileCommon(type: ${type}, fileUploads: $fileUploads) { file_name file_url file_size }}",\n  "variables": {\n    "fileUploads": [null]\n  }\n}`
    );
    formdata.append("map", '{ "0": ["variables.fileUploads"]}');
    formdata.append("0[]", blobValue, file.name);
    const res = await axios({
      url: `${process.env.VUE_APP_APOLLO_SERVER}/graphql/admin`,
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + getToken(),
      },
    });

    return res?.data?.data?.uploadFileCommon?.[0];
  } catch (error) {
    console.log(error);
    return { file_name: "", file_url: "", file_size: "" };
  }
};
