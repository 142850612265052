import { InMemoryCache } from "@apollo/client/core";

const cache = new InMemoryCache({
  typePolicies: {
    ConfigNotification: {
      keyFields: false,
    },
    ConfigUser: {
      keyFields: false,
    },
    MatchingFeedback: {
      keyFields: false,
    },
    User: {
      keyFields: false,
    },
    ConfigItem: {
      keyFields: false,
    },
    MstCategories: {
      keyFields: false,
    },
    MstPrefs: {
      keyFields: false,
    },
    CitiesMstPrefs: {
      keyFields: false,
    },
  },
}) as any;

export default cache;
