import { toastMsgError } from "@/core/helpers/toastMessage";
import JwtService, { getToken } from "@/core/services/JwtService";
import { ApolloClient, createHttpLink, ApolloLink } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import cache from "./cache";
import { CONTEXT_NAME } from "@/core/constant/const";

const UNAUTHORIZED_STATUS_CODE = 401;

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions }) => {
      // TODO: do something here with error
      if (extensions?.code === UNAUTHORIZED_STATUS_CODE) {
        JwtService.destroyToken();
        window.location.href = `${window.location.origin}/sign-in`;
        return;
      }
      toastMsgError(message);
    });
  return;
});

const authMiddleware = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const authHttpLink = createHttpLink({
  uri: `${process.env.VUE_APP_APOLLO_SERVER}/graphql/admin/auth`,
});

const httpLink = createHttpLink({
  uri: `${process.env.VUE_APP_APOLLO_SERVER}/graphql/admin`,
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink as any,
    authMiddleware,
    ApolloLink.split(
      (operation) => {
        return operation.getContext().clientName === CONTEXT_NAME.AUTH;
      },
      authHttpLink, //if above
      httpLink
    ),
  ]),
  cache,
});

export { apolloClient };
