import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(contentType = ""): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `bearer ${JwtService.getToken()}`;
    if (contentType == "") {
      ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
        "application/json";
    } else {
      ApiService.vueInstance.axios.defaults.headers.post["Content-Type"] =
        contentType;
    }
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static getData(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static getDataById(
    resource: string,
    slug = "" as string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}/${slug}`, params);
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static getDataDetail(
    resource: string,
    code = "" as string,
    slug = "" as string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(
      `${resource}/${code}/${slug}`,
      params
    );
  }

  /**
   * @description send the GET HTTP request
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static all(params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios(params);
  }
  public static upsertData(
    resource: string,
    code = "" as string,
    slug = "" as string,
    id = "" as string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const url = id
      ? `${resource}/${code}${slug}/${id}`
      : `${resource}/${code}${slug}`;
    return ApiService.vueInstance.axios.post(url, params);
  }

  public static upsertDataViolin(
    resource = "" as string,
    id = "" as string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const url = id ? `${resource}/${id}` : `${resource}`;
    return ApiService.vueInstance.axios.post(url, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static getPaginnation(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}=${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params?: AxiosRequestConfig,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    if (slug) {
      return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    } else {
      return ApiService.vueInstance.axios.put(`${resource}`, params);
    }
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
